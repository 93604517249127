@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

.form-container {
    padding: 5rem;
  .form-row {
    align-items: flex-start;
    gap: 1rem;
  }

  .input-container {
    min-height: 5.625rem;
    position: relative;
    margin-bottom: 0.5rem;;
  }

  .error-message {
    color: awsui.$color-text-status-error;
    font-size: 0.75rem;
    position: absolute;
    bottom: -1.25rem;
    left: 0;
  }
}
